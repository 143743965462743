
import React, { Component } from 'react';
import $ from 'jquery';
import { SaveExceptionToSentry } from "./RUtility";
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error);
        var exception = new Object();
        exception.Type = "Client Component Error";

        var userData = null;
        try {
            var user = localStorage.getItem("UserData");
            if (user) {
                userData = JSON.parse(user);

            }
        } catch (e) {

        }
        

        

        exception.Message = (userData ? userData.F1 : "") + " " + error + JSON.stringify(errorInfo);
        SaveExceptionToSentry(exception, "Client Component Error", "", JSON.stringify(errorInfo))
        console.log(errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div style={{ width: '100%', padding: '22px', paddingTop: '20px', height: 'calc( 100vh - 100px )', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div>
                    <img src="/images/error.png" /><h1 style={{ fontSize: '19px', fontWeight: 100, textAlign: 'center', fontFamily: '"Muli"', lineHeight: '1.5' }}>Oh!! Something went wrong, Please do reload the Pinnacle<br /><br />If you still facing this issue, Please reach us <a href="tel:9100181181">9100 181 181</a>
                    </h1></div></div>


           
        }

        return this.props.children;
    }
}