import React from 'react';
import $ from 'jquery';

export const DataKeys = {
    MD: "masterdata",
    WD: "walldata",
    UD: "userdata",
    U: "user",
    AT: "authtoken",
    UI: "userinfo",
    
}

var data = {
    MD: {},
    WD: [],
    User: null,
    UD: {},
    AT: "",
    UI: [],
}
function reducer(state = data, action) {
    switch (action.type) {
        //case "Car":
        //    return {
        //        vehicle: "It is a Car",
        //        products: action.products
        //    };
        //case "Bike":
        //    return {
        //        vehicle: "It is a Bike",
        //        products: action.products,

        //    };
        case DataKeys.MD:
            return {
                U: state.U,
                UD: state.UD,
                WD: state.WD,
                AT: state.AT,
                MD: action.MD,
                UI: state.UI
            }
        case DataKeys.WD:
            return {
                MD: state.MD,
                U: state.U,
                UD: state.UD,
                AT: state.AT,
                WD: state.WD
            }
        case DataKeys.UD:
           
            return {
                MD: state.MD,
                U: state.U,
                WD: state.WD,
                AT: state.AT,
                UD: action.UD,
                UI: state.UI
            }
        case DataKeys.U:

            return {
                MD: state.MD,
                UD: state.UD,
                WD: state.WD,
                AT: state.AT,
                U: action.U,
                UI: state.UI
            }
        case DataKeys.AT:
            return {
                MD: state.MD,
                U: state.U,
                UD: state.UD,
                WD: state.WD,
                AT: action.AT,
                UI: state.UI
            }
        case DataKeys.UI:
            return {
                MD: state.MD,
                U: state.U,
                UD: state.UD,
                WD: state.WD,
                AT: state.AT,
                UI: action.UI
            }
        default:
            return state;
    }
}

export default reducer;

// #region actions

export function StoreMasterData(MD) {
   
    return {
        type: DataKeys.MD,
        MD : MD
    }
}
export function StoreUserInfoData(UI) {

    return {
        type: DataKeys.UI,
        UI: UI
    }
}
export function StoreWallData(WD) {
    return {
        type: DataKeys.WD,
        WD: WD
    }
}

export function StoreUserData(UD) {
   
    return {
        type: DataKeys.UD,
        UD: UD
    }
}

export function StoreUser(U) {

    return {
        type: DataKeys.U,
        U : U
    }
}
export function StoreAuthToken(AT) {
   
    return {
        type: DataKeys.AT,
        AT: AT
    }
}
// #endregion actions


